const Text = [
    {
        id:1,
        header: "DIGITAL MARKETING",
        description: "Today 70% of the business leads are generated from online search, be it desktop or mobile."
    },
    {
        id:2,
        header:"BUSINESS PROCESS AUTOMATION",
        description:"We make your entire business process more cost-efficient, streamlined, error-proof and accessible."
    },
    {
        id:3,
        header:"CLOUD HOSTING",
        description:"Right from hosting a basic website to complex ecommerce application to a database driven portals, we at Senseware provide complete managed hosting services to over 600+ clients."
    },
    {
        id:4,
        header:"DIGITAL BRAND BUILDING",
        description:"At Senseware, we integrate website, mobile marketing and digital communication channels for customer acquisition and retention."
    },
    {
        id:5,
        header:"ECOMMERCE PLATFORM",
        description:"Selling your products/services online - is a challenge. It’s an art to stand out amongst the clutter. We have the expertise to reach out to the precise target audience. "
    },
    {
        id:6,
        header:"MOBILE APPS",
        description:"Senseware puts you in forefront of the brand building and improved customer engagement through effective mobile strategy."
    },
    {
        id:7,
        header:"ONLINE HOTEL REVENUE MANAGEMENT",
        description:"Alia and Ranveer are all over the place with their recent MakeMyTrip ads highlighting various scenarios where online hotel and travel bookings are always the better deal than walk-in reservations with a ‘trusted friend’s hotel’."
    }
]

export default Text



// {
    //     id:8,
    //     header:"RESPONSIVE WEBSITE",
    //     description:"Creating a website is a powerful communication tool. Today 70% of the Business leads are generated from online search. A website is a unique way to communicate with the world."
    // },