import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import _ from 'lodash';
import './Slider.css';
import Navbar from './Navbar';
import Text from "../src/text.js"

const Slider = () => {
  const [images, setImages] = useState([]);
  // const [texts, setTexts] = useState([]);
  const [index, setIndex] = useState(0);
  const [prevMouseY, setPrevMouseY] = useState(0);
  const [pause, setPause] = useState(false);

  // const fetchTextContent = async (textPath) => {
  //   try {
  //     const response = await fetch(textPath);
  //     const textContent = await response.text();
  //     return { text: textContent.trim() };
  //   } catch (error) {
  //     console.error(`Error reading or parsing text file: ${textPath}`, error);
  //     return { text: '' };
  //   }
  // };

  useEffect(() => {
    // Load images dynamically
    const importAllImages = (r) => r.keys().map(r);
    const imageContext = require.context('!!file-loader!./images', false, /\.(png|jpe?g|svg)$/);
    const imagePaths = importAllImages(imageContext);
    setImages(imagePaths);


    
    // Load text content dynamically
    // const importAllTexts = (r) => r.keys().map(r);
    // const textContext = require.context('!!raw-loader!./texts', false, /\.txt$/);
    // const textPaths = importAllTexts(textContext);
    // console.log('text' + textContext)

    // const loadTextContents = async () => {
    //   const textContents = await Promise.all(textPaths.map(fetchTextContent));
    //   setTexts(textContents);
    // };

    // loadTextContents();

    // Start automatic sliding
    const intervalId = setInterval(() => {
      if (!pause) {
        setIndex((prevIndex) => (prevIndex + 1) % images.length);
      }
    }, 3000);

    return () => clearInterval(intervalId);
  }, [images.length, pause]);

  const props = useSpring({
    transform: `translateX(${-index * 100}%)`,
    config: { duration: 1500 },
  });

  const handleMouseMove = _.debounce((e) => {
    const { clientY } = e;
    const windowHeight = window.innerHeight;

    const percent = (clientY / windowHeight) * 100;
    const newIndex = Math.floor(percent / (100 / images.length));
    const direction = clientY > prevMouseY ? 'down' : 'up';

    if (direction === 'down') {
      setIndex(newIndex);
    } else {
      setIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
    }

    setPrevMouseY(clientY);
  }, 100);

  const handleClick = () => {
    setPause(true);
  };

  return (
    <div>
      <Navbar />
      <div className="slider" onMouseMove={handleMouseMove} onClick={handleClick}>
        <animated.div className="slides" style={props}>
          {
            images.map((image, i) => (
                Text.map(
                    text => 
                        i === text.id ? (
                            <div key={i} className="slide" style={{ backgroundImage: `url(${image.default})` }}>
                            <div className="slide-content" style={{ backgroundColor: 'rgb(15 16 17 / 70%)'}}>
                                <h2>{text.header}</h2>
                                <p>{text.description}</p>
                                {/* <div className='middle'>
                                  <button className='btn btn1'>READ MORE</button>
                                </div> */}
                            </div>
                            </div>
                        ) : ""
                    )
            
          ))}
        </animated.div>
      </div>
    </div>
  );
};

export default Slider;


// const Slider = () => {
//   const [images, setImages] = useState([]);
//   const [index, setIndex] = useState(0);

//   useEffect(() => {
//     // Load images dynamically (customize this based on your project)
//     const importAll = (r) => r.keys().map(r);
//     const imageContext = require.context('!!file-loader!./images', false, /\.(png|jpe?g|svg)$/);
//     const imagePaths = importAll(imageContext);
//     setImages(imagePaths);

//     // Set up automatic slider
//     const intervalId = setInterval(() => {
//       setIndex((prevIndex) => (prevIndex + 1) % images.length);
//     }, 3000); // Change slide every 3 seconds

//     // Cleanup interval on component unmount
//     return () => clearInterval(intervalId);
//   }, [images]);

  

//   const handleMouseMove = (e) => {
//     const { clientY } = e;
//     const windowHeight = window.innerHeight;

//     // Calculate the percentage of cursor position relative to window height
//     const percent = (clientY / windowHeight) * 100;

//     // Adjust the index based on the cursor position
//     const newIndex = Math.floor(percent / (100 / images.length));

//     // Update the index, but make sure it moves to the previous slide when moving up
//     const reversedIndex = images.length - 1 - newIndex;
//     setIndex(reversedIndex);
//   };

//   return (
//     <div className="slider" onMouseMove={handleMouseMove}>
        
//     <div className="slides-container">
//     <nav className="navbar">
//             <div className="logo">
//                 {/* <img src={myLogo} alt='logo1' /> */}
//                 <h4>mylogo</h4>
//             </div>
//             <div className="menu-toggle">
//             <input type="checkbox" />
//             <span></span>
//             <span></span>
//             <span></span>
//             <ul className="nav-links">
//                 <li>Home</li>
//                 <li>About</li>
//                 <li>Contact</li>
//             </ul>
//             </div>
//         </nav>
//       <div className={`slide ${index === 0 ? 'red' : ''}`}>
//         <img src={myImage1} alt="Slide 1" />
//         <div className="content" style={{ backgroundColor: 'rgba(0,204,255,.7)'}}>
//           <h2 style={{ color: 'white' }}>DIGITAL MARKETING</h2>
//           <p style={{ color: 'white' }}>Some text describing slide 1</p>
//         </div>
//       </div>

//       <div className={`slide ${index === 1 ? 'green' : ''}`}>
//         <img src={myImage2} alt="Slide 2" />
//         <div className="content" style={{ backgroundColor: 'rgba(214,102,207,.7)'}}>
//           <h2 style={{ color: 'white' }}>DIGITAL BRAND BUILDING</h2>
//           <p style={{ color: 'white' }}>Some text describing slide 2</p>
//         </div>
//       </div>

//       <div className={`slide ${index === 2 ? 'blue' : ''}`}>
//         <img src={myImage3} alt="Slide 3" />
//         <div className="content" style={{ backgroundColor: 'rgba(121, 160, 196, 0.8)'}}>
//           <h2 style={{ color: 'white' }}>RESPONSIVE WEBSITE</h2>
//           <p style={{ color: 'white' }}>Some text describing slide 3</p>
//         </div>
//       </div>
//       <div className={`slide ${index === 3 ? 'blue' : ''}`}>
//         <img src={myImage4} alt="Slide 4" />
//         <div className="content" style={{ backgroundColor: 'rgba(191,191,191,.7)'}}>
//           <h2 style={{ color: 'white' }}>ECOMMERCE PLATFORM</h2>
//           <p style={{ color: 'white' }}>Some text describing slide 3</p>
//         </div>
//       </div><div className={`slide ${index === 4 ? 'blue' : ''}`}>
//         <img src={myImage5} alt="Slide 5" />
//         <div className="content" style={{ backgroundColor: 'rgba(0,199,82,.7)'}}>
//           <h2 style={{ color: 'white' }}>CLOUD HOSTING</h2>
//           <p style={{ color: 'white' }}>Some text describing slide 3</p>
//         </div>
//       </div><div className={`slide ${index === 5 ? 'blue' : ''}`}>
//         <img src={myImage6} alt="Slide 6" />
//         <div className="content" style={{ backgroundColor: 'rgba(201, 74, 74, 0.8)'}}>
//           <h2 style={{ color: 'white' }}>ONLINE HOTEL REVENUE MANAGEMENT</h2>
//           <p style={{ color: 'white' }}>Some text describing slide 3</p>
//         </div>
//       </div><div className={`slide ${index === 6 ? 'blue' : ''}`}>
//         <img src={myImage7} alt="Slide 7" />
//         <div className="content" style={{ backgroundColor: 'rgba(248,167,36,.7)'}}>
//           <h2 style={{ color: 'white' }}>BUSINESS PROCESS AUTOMATION</h2>
//           <p style={{ color: 'white' }}>Some text describing slide 3</p>
//         </div>
//       </div><div className={`slide ${index === 7 ? 'blue' : ''}`}>
//         <img src={myImage8} alt="Slide 8" />
//         <div className="content" style={{ backgroundColor: 'rgba(241,36,65,.7)'}}>
//           <h2 style={{ color: 'white' }}>MOBILE APPS</h2>
//           <p style={{ color: 'white' }}>Some text describing slide 3</p>
//         </div>
//       </div>
//     </div>
//   </div>
// );
// };


