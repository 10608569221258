// Navbar.js
import React, { useState } from 'react';
import './Navbar.css';
import logo1 from './images/logo/logo.jpeg';
// import logo2 from './images/logo/logo2.jpeg'


const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const closeMenu = () => {
    setShowMenu(false);
  };

  return (
    <nav className="navbar">
        <a href='https://senseware.net/index.aspx'>
          <img src={logo1} alt='sensewarelogo' id='logo'/>
        </a>
      <a href="https://senseware.net/request-a-project.aspx" className="new-project">
        REQUEST A PROJECT

        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right-circle" viewBox="0 0 16 16" style={{ marginLeft: '10px', marginBottom: '0px'}}>
        <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
        </svg>    
      </a>


      <div className={`menu-toggle ${showMenu ? 'show' : ''}`} onClick={toggleMenu}>
        <span className='span1'></span>
        <span className='span2'></span>
        <span className='span3'></span>
      </div>
      <ul className={`nav-links ${showMenu ? 'show' : ''}`}>
        <li><a href='https://senseware.net/index.aspx'>HOME</a></li>
        <li><a href='https://senseware.net/about.html'>ABOUT</a></li>
        <li><a href='https://senseware.net/services.html'>SERVICES</a></li>
        <li><a href='https://senseware.net/portfolio.html'>PORTFOLIO</a></li>
        <li><a href='https://senseware.net/contact.aspx'>CONTACT</a></li>
        <li><a href='http://senseware-infomedia.blogspot.in/'>BLOG</a></li>
        <li><a href='https://senseware.net/request-a-project.aspx'>START A PROJECT</a></li>
      </ul>
      {showMenu && <button className="close-button" onClick={closeMenu}>&times;</button>}
    </nav>
  );
};

export default Navbar;
